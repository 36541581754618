<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Playbooks <span>Completed Questions</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="playbook-completed-questions-form" class="headline_form">
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="capsule_elm">
                            <h5>Display Completed Questions</h5>
                            <label for="show_completed_question" class="switch_option capsule_btn border-0 mb-3">
                                <input type="checkbox" id="show_completed_question" :true-value="1" :false-value="0" v-model="form.display_progressbar" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Progress Bar Color</label>
                                <Field autocomplete="off" name="progressbar_color" v-model="form.progressbar_color" type="text" label="element color">
                                    <color-picker v-model="form.progressbar_color" :classes="{ 'has-error': errors.progressbar_color }" />
                                </Field>
                                <ErrorMessage name="progressbar_color" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="playbookUpdateLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="playbookUpdateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="playbookUpdateLoader"></i> {{ playbookUpdateLoader ? 'Updating' : 'Update' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))

    export default {
        name: 'Playbook Content Headline',

        data () {
            return {
                form: {
                    display_progressbar: 0,
                    progressbar_color: '',
                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ColorPicker,
        },

        watch: {
            selectedPlaybook () {
                const vm = this;

                vm.resetForm();
            },
        },

        computed: mapState({
            selectedPlaybook: state => state.playbookModule.selectedPlaybook,
            playbookUpdateLoader: state => state.playbookModule.playbookUpdateLoader,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePlaybookSetting: 'playbookModule/updatePlaybookSetting',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    playbook_id: vm.selectedPlaybook.id,
                    display_progressbar: vm.selectedPlaybook.display_progressbar ? vm.selectedPlaybook.display_progressbar : 0,
                    progressbar_color: vm.selectedPlaybook.progressbar_color ? vm.selectedPlaybook.progressbar_color : '',
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError  = setFieldError;

                vm.updatePlaybookSetting(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>
<style scoped>
    .headline_form{
        display: flex;
        flex-direction: column;
        height: calc(100vh - 99px);
    }
</style>

